import React from "react";
import Header from "../../../Header1";
import Footer from "../../../Footer2";
import HeroSection from "./HeroSection";
import About from "./About";
import Steps from "./Steps";
import AboutTwo from "./AboutTwo";
import Contact from "./Contact";
import Features from "./Features";

const HIS = () => {
	return (
		<>
			<Header />
			<div className="page-content">
				<HeroSection />
				<About />
				<Steps />
				<AboutTwo />
				<Features />
				<Contact />
			</div>
			<Footer />
		</>
	);
};
export default HIS;
