import React from "react";
import dataBlogs from "../../../data.json";
const URL = require("../../../constants");

const Blogs = () => {
	return (
		<>
			<section>
				<div className="container">
					<div className="row">
						<div className="col-lg-12 ml-auto mr-auto text-center wow fadeInRight">
							<div className="section-title">
								<div className="title-effect">
									<div className="bar bar-top"></div>
									<div className="bar bar-right"></div>
									<div className="bar bar-bottom"></div>
									<div className="bar bar-left"></div>
								</div>
								<h6 className="font-weight-bold">Our Machine Portfolio</h6>
								<h2 className="title font-weight-bold" style={{color: 'black'}}>
									We deal with a wide array of Lab Machines 
								</h2>
							</div>
						</div>
						<div className="col-md-12 wow fadeInLeft">
							<div
								className="owl-carousel owl-theme"
								data-items="5"
								data-md-items="3"
								data-sm-items="1"
								data-autoplay="true"
							>{dataBlogs.data.home[0].content.tabs[2].machinePics.map(
									(data, index) => (
										<div className="item" key={index}>
											<div className="team-member style-2">
												<div className="team-images">
													<img
														className="img-fluid radius box-shadow"
														src={data["img-src"]}
														alt=""
													/>
												</div>
												<div className="team-description">
													<h5>{data["name"]}</h5>
												</div>
											</div>
										</div>
									)
								)}

							</div>
						</div>

					</div>
				</div>
			</section>
		</>
	);
};
export default Blogs;
