import React from "react";
import Header1 from "../../Header1";
import Footer2 from "../../Footer2";
import PageContent from "./content";

const HomeTwo = () => {
	return (
		<>
			<div className="page-wrapper">
				<Header1 />
				<PageContent />
				<Footer2 />
			</div>
		</>
	);
};
export default HomeTwo;
