import React from "react";
import dataTech from "../../data.json";
const URL = require("../../constants");


const Tech = () => {
    return (
        <>
         <section className="pos-r o-hidden pb-2" data-bg-img="images/pattern/03.png">
            <div className="container">
                <div className="row text-center">
                    <div className="col-lg-8 col-md-12 ml-auto mr-auto">
						<div className="section-title">
							<div className="title-effect title-effect-2">
								<div className="ellipse"></div>{" "}
								<i className="la la-cubes"></i>
							</div>
							<h2 className="title">
                                Our Database Tools
							</h2>
						</div>
                    </div>
                    <div className="col-md-12 wow bounceInRight">
                        <div
								className="owl-carousel"
								data-center="true"
								data-items="5"
								data-lg-items="5"
								data-md-items="3"
								data-sm-items="2"
								data-autoplay="true"
						>
                            {dataTech.data.home[0].content.databases.map(
                            (data,index) =>(
                                    <div className="item" key={index}>
                                        <div className="featured-item style-3">
                                            <div className="feature-icon">
                                                <img className="img-fluid radius box-shadow" src={`${URL.img_url}${data["img-url"]}`}/>
                                                <p className="post-categories">{data["name"]}</p>
                                            </div>
                                        </div>
                                    </div>
                            )
                        )}
                        </div>
					</div>
                </div>
             </div>
         </section>
        </>
    );
};

export default Tech;