import React from "react";
import dataHerosection from "../../data.json";

const HeroSectionTwo = () => {
	return (
		<>
			{/* hero section :: start */}
			<section
				className="fullscreen-banner banner p-1"
				data-bg-img="images/pattern/01.png"
			>
				<div className="hero-bg">
					<img className="img-fluid" src="images/bg/01.png" alt="" />
				</div>
				<div className="align-center pt-0">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 col-md-12 img-side">
								<img
									className="img-center wow rubberBand"
									src="images/banner/computer-infrastructure.png"
									alt=""
								/>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 col-md-12 md-mt-5 ml-auto">
								<h1
									className="mb-4 font-weight-normal wow fadeInUp"
									data-wow-duration="1.5s"
								>
							
									<span className="font-weight-bold text-capitalize" style={{color: '#1F4E79'}}>
										Database Migration
									</span>{" "}
								</h1>
								<h4
									className="mb-3 wow font-weight-bold fadeInUp text-capitalize"
									data-wow-duration="2s"
									data-wow-delay="0.3s"
								>
									Transferring and Upgrading your Database with ease
								</h4>
								<a
									className="btn btn-theme wow fadeInUp"
									data-wow-duration="3s"
									data-wow-delay="0.5s"
									href="/contact"
									data-text="Contact Us"
								>
									{" "}
									<span>C</span>
									<span>o</span>
									<span>n</span>
									<span>t</span>
									<span>a</span>
									<span>c</span>
									<span>t</span> <span> </span>
									<span> </span>
									<span>U</span>
									<span>s</span>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="round-shape"></div>
			</section>
		</>
	);
};

export default HeroSectionTwo;
