import React from "react";
import dataAbout from "../../../data.json";
const About = () => {
	return (
		<>
			<section
				className="pos-r right bg-pos-l py-10"
			>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-7 col-md-12 image-column ml-auto mr-auto p-0 pr-lg-5">
						<div className="">
								<img
									className="img-center wow bounce"
									src="images/banner/developer.png"
									alt="software-developer"
								/>
						</div>
						
					</div>
						<div className="col-lg-5 ml-auto col-md-12 md-mt-5">
							<div className="section-title mb-4">
								<div className="title-effect">
									<div className="bar bar-top"></div>
									<div className="bar bar-right"></div>
									<div className="bar bar-bottom"></div>
									<div className="bar bar-left"></div>
								</div>
								<h6 className="wow bounce">
									{dataAbout.data.home[0].content.about.title}
								</h6>
								<h3 className="wow bounce font-weight-bold text-black">
									{
										dataAbout.data.home[0].content.about
											.description
									}
								</h3>
							</div>
							<ul className="list-unstyled list-icon mb-4 wow bounce font-weight-bold">
								{dataAbout.data.home[0].content.about.details.map(
									(detail, index) => (
										<li className="mb-3" key={index}>
											<i className="la la-check"></i>
											{detail}
										</li>
									)
								)}
							</ul>
							<a
								className="btn btn-border btn-circle wow bounce"
								href="/about"
								data-text="See About"
							>
								{" "}
								<span>S</span>
								<span>e</span>
								<span>e</span>
								<span> </span> <span> </span>
								<span>A</span>
								<span>b</span>
								<span>o</span>
								<span>u</span>
								<span>t</span>
							</a>
							
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
export default About;
