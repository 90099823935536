import React from "react";
import dataHerosection from "../../../data.json";

const HeroSection = () => {
	return (
		<>
			{/* hero section :: start */}
			<section
				className="fullscreen-banner banner p-0 pos-r o-hidden"
				data-bg-img="images/bg/03.png"
			>
				<div className="bg-animation">
					<img
						className="zoom-fade"
						src="images/pattern/01.png"
						alt=""
					/>
				</div>
				<div className="align-center">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 col-md-12">
								<div
									className="bnr-box px-8 py-8 xs-px-3 xs-py-3 radius"
								>
									<div className="row align-items-center">
										<div className="col-lg-5 col-md-12 order-lg-8">
											<img
												className="img-center"
												src="images/banner/lab-machine-2.png"
												alt=""
												
											/>
										</div>
										<div
											className="col-lg-7 col-md-12 order-lg-1 md-mt-5 wow tada"
											data-wow-duration="1.5s"
										>
											<h1 className="mb-1" style={{color: '#1f4e79'}}>
												Hospital Machine & System Integration
											</h1>
											<p className="lead mb-4 font-weight-bold" >
												Connecting your hospital machines to your Hospital Information System or
												Lab Information System
											</p>
											<a
												className="btn btn-white"
												href="/#"
												data-text= "Contact Us"
											>
												{" "}
												<span>C</span>
												<span>o</span>
												<span>n</span>
												<span>t</span> 
												<span>a</span>
												<span>c</span> 
												<span>t</span> 
												<span> </span>{" "}
												<span>U</span>
												<span>s</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default HeroSection;
