import React from "react";


const HeroSection = () => {
    return (
        <>
        <section
			className="fullscreen-banner banner p-0"
            data-bg-img="images/bg/lightbulb-2.png"
            style={{height: "100vh"}}
		>
        <div className="align-center pt-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 col-md-10 wow fadeInLeft"
                        data-wow-duration="1.5s">
                    {/* <img className="img-fluid" src="images/banner/maintenance-tagline-3.png"/> */}
                        <h5 style={{color: 'white'}}>
						Support and maintenance
				    	</h5>
                        <h1 className="mb-2 text-black font-italic" style={{fontWeight: 725}}>
                           Keeping the heart 
                        </h1>
                        <h1 className="mb-2 text-black font-italic" style={{fontWeight: 725}}>
                          Of your business
                        </h1>
                        <h1 className="mb-2 text-black font-italic" style={{fontWeight: 725}}>
                         up and running
                        </h1>
                        <br />
                        <a
						    className="btn btn-theme right" 
						    href="/contact"
						    data-text="Contact Us"
					    >
                        {" "}
                        <span>C</span>
						<span>o</span>
						<span>n</span>
                        <span>t</span>
						<span>a</span>
						<span>c</span>
						<span>t</span>
                    	<span> </span>{" "}
						<span>U</span>
						<span>s</span>
					    </a>
                    </div>
                </div>
            </div>
        </div>
            
        </section>
        </>
    );
}

export default HeroSection;