import React from "react";
import dataAbout from "../../data.json";

const Hardware = () => {
    return (
        <>
	    <section className="p-1">
			<div className="container wow fadeInLeft"
		    	data-wow-duration="2s">
				<div className="row align-items-center">
					<div className="col-lg-6 col-md-12 order-lg-12">
						<img
                            className="img-fluid w-100"
							src="images/banner/hardware.png"
							alt=""
						/>
					</div>
	    			<div className="col-lg-6 col-md-12 md-mt-5 order-lg-1 font-weight-bold">
						<div className="section-title mb-4">
							<div className="title-effect">
								<div className="bar bar-top"></div>
								<div className="bar bar-right"></div>
								<div className="bar bar-bottom"></div>
								<div className="bar bar-left"></div>
							</div>
							<h6 >Hardware Maintenance</h6>	
                            <h2 className="font-weight-bold">
                                Providing quality Hardware, Network and Infrastructure maintenance to your 
                                valuable business assets
							</h2>
						</div>
						<ul className="list-unstyled list-icon mb-4">
							<li className="mb-3">
								<i className="la la-check"></i>
                                    Reduced maintenance costs
								</li>
								<li className="mb-3">
									<i className="la la-check"></i>
									Longer equipment lifecycle
								</li>
								<li className="mb-3">
									<i className="la la-check"></i>
                                    Flexible coverage and agreements
								</li>
                                <li className="mb-3">
									<i className="la la-check"></i>
                                    Better up-time and responsiveness 
								</li>
                                <li className="mb-3">
									<i className="la la-check"></i>
                                    Dedicated user-friendly support
								</li>
                                
							</ul>
						</div>
					</div>
				</div>
		</section>
	    <section className="pos-r o-hidden text-center">
			<div className="bg-animation">
				<img
					className="zoom-fade"
					src="images/pattern/03.png"
					alt=""
				/>
			</div>
			<div className="container wow tada"
			data-wow-duration="1.5s">
				<div className="row">
					<div className="col-lg-12 mt-3">
						<div className="title-effect title-effect-2">
							<div className="ellipse"></div>{" "}
							<i className="la la-cubes"></i>
						</div>
						<h2>Our Hardware Maintenance services</h2>
					</div>
				</div>
				<div className="row">
					{dataAbout.data.services[4].hardware.map(
						(feature, index) => (
							<div className="col-lg-4 mt-3 ml-auto mr-auto" key={index}>
								<div className="featured-item style-4">
									<div className="featured-title">
										<h5 className="font-weight-bold" style={{color: feature["color"]}}>
											{feature["title"]}
										</h5>
									</div>
									<div className="featured-desc">
										<p className="font-weight-bold">{feature["description"]}</p>
									</div>
								</div>
							</div>
						)
					)}
				</div>
			</div>
		</section>
    </>
    );
}

export default Hardware;