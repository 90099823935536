import React from "react";
import Header from "../../../Header1";
import Footer from "../../../Footer2";
import About from "./About";
import HeroSection from "./HeroSection";
import Features from "./Features";
import Contact from "./Contact";
import Machines from "./Machines";

const ServiceTwo = () => {
	return (
		<>
			<Header />
			<HeroSection />
			<div className="page-content">
				<About />
				<Features />
				<Machines />
				<Contact />
			</div>
			<Footer />
		</>
	);
};
export default ServiceTwo;
