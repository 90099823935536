import React from "react";
import dataAbout from "../../data.json";

const About = () => {
	return (
		<>
		<section
			className="p-3 pt-5 wow fadeInLeft"
			data-wow-duration="1.5s"
		>
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6 col-md-12 p-1">
						<div className="blink-img">
							<img
								className="img-fluid blinkblink"
								src="images/pattern/01.png"
								alt=""
							/>
						</div>
						<img
							className="img-center"
							src="images/banner/cutting-edge-tech-2.png"
							alt=""
							/>
					</div>
					<div className="col-lg-6 ml-auto col-md-12 md-mt-5 font-weight-bold">
						<div className="section-title mb-4">
							<div className="title-effect">
								<div className="bar bar-top"></div>
								<div className="bar bar-right"></div>
								<div className="bar bar-bottom"></div>
								<div className="bar bar-left"></div>
							</div>
							<h6>Our Core Values</h6>
							<h2 className="font-weight-bold">Taking the Lead In Cutting Edge Technology</h2>
						</div>
						<ul className="list-unstyled list-icon mb-4">
							{dataAbout.data.about[0].about.details.map(
								(detail, index) => (
									<li className="mb-3" key={index}>
										<i className="la la-check"></i>
										{detail}
									</li>
								)
							)}
						</ul>
					</div>
				</div>
			</div>
		</section>
		<section className="banner wow fadeInRight"
		data-wow-duration="1.5s">
				<div className="container">
					<div className="row align-items-center"
					>
						<div className="col-lg-6 col-md-12 order-lg-12">
							<div className="blink-img">
								<img
									className="img-fluid blinkblink"
									src="images/pattern/01.png"
									alt=""
								/>
							</div>
							<img
								className="img-fluid w-100"
								src="images/banner/it-partner.png"
								alt=""
							/>
						</div>
						<div className="col-lg-6 col-md-12 md-mt-5 order-lg-1 font-weight-bold">
							<div className="section-title mb-4">
								<div className="title-effect">
									<div className="bar bar-top"></div>
									<div className="bar bar-right"></div>
									<div className="bar bar-bottom"></div>
									<div className="bar bar-left"></div>
								</div>
								<h6 >What Makes Us Different</h6>
								<h2 className="font-weight-bold">A distinguished and reliable IT Partner</h2>
							</div>
							<ul className="list-unstyled list-icon mb-4">
								<li className="mb-3">
									<i className="la la-check"></i>
									We focus on leveraging latest technologies while using proven 
									and cost effective methods for each client. 
								</li>
								<li className="mb-3">
									<i className="la la-check"></i>
									We work very closely with all end-users to achieve their objectives as 
									that is the highest priority. 
									
								</li>
								<li className="mb-3">
									<i className="la la-check"></i>
									We believe in adapting quickly to the changing environment and see 
									every change and challenge as an opportunity to set us apart and distinguish ourselves. 
								</li>
								
							</ul>
						
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default About;
