import React from "react";
import dataTabs from "../../../data.json";
const URL = require("../../../constants");

const Tabs = () => {
	let tabCount = 1;
	let contentCount = 1;
	return (
		<section className="pos-r o-hidden col-lg-12 custom-mt-6 font-weight-bold">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-12 col-md-12 col-xs-10 mr-auto ml-auto text-center">
						<div className="section-title">
							<div className="title-effect">
								<div className="ellipse"></div>{" "}
								<i className="la la-question"></i>
							</div>
							<h2 className="title">
                                What we Do
							</h2>
						</div>
					</div>
					<div className="col-lg-12 ml-auto mr-auto">
						<div className="tab style-2 wow zoomIn">
							{/* Nav tabs */}
							<nav>
								<div
									className="nav nav-tabs"
									id="nav-tab"
									role="tablist"
								>
									{dataTabs.data.home[0].content.tabs.map(
										(data, index) => (
											<a
												key={index}
												className={`nav-item nav-link ${
													tabCount === 1
														? "active"
														: ""
												}`}
												id={`"nav-tab${tabCount}`}
												data-toggle="tab"
												href={`#tab1-${tabCount}`}
												role="tab"
												aria-selected={
													tabCount++ === 1
														? "true"
														: "false"
												}
											>
												{" "}
												<i
													className={`${data["icon"]}`}
												></i>
												<h4 className="font-weight-bold">{`${data["title"]}`}</h4>
											</a>
											)
										)}
								</div>
							</nav>
							{/* Tab panes */}
							<div className="tab-content" id="nav-tabContent">
								{dataTabs.data.home[0].content.tabs.map(
									(data, index) => (
										<div
											key={index}
											role="tabpanel"
											className={`tab-pane fade ${
												contentCount === 1
													? "show active"
													: ""
											}`}
											id={`tab1-${contentCount++}`}
										>
											<div className="row align-items-center">
												<div className="col-lg-5 col-xs-12 col-md-12">
													<img
														className="img-fluid  ml-auto mr-auto"
														src={`${URL.img_url}${data["img_url"]}`}
														alt=""
													/>
												</div>
												<div className="col-lg-7 col-md-12 md-mt-5">
													<h3 className="font-weight-bold">
														{data["title"]}
													</h3>
													<p className="mb-3">
														{data["content"]}
													</p>
													<ul className="custom-li list-unstyled list-icon-2 d-inline-block">
														{data.features.map(
															(
																feature,
																index
															) => (
																<li key={index}>
																	{feature}
																</li>
															)
														)}
													</ul>
													<a href={data["link"]}
													className="btn btn-theme"
													data-wow-duration="3s"
													data-wow-delay="0.5s"
													href={data["link"]}
													data-text="Learn More">
													<span>L</span>
													<span>e</span>
													<span>a</span>
													<span>r</span>
													<span>n</span>
													<span> </span>{" "}
													<span>M</span>
													<span>o</span>
													<span>r</span>
													<span>e</span>
													</a>
												</div>
											</div>
										</div>
									)
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
export default Tabs;
