import React from "react";
import dataAbout from "../../data.json";

const About = () => {
	return (
		<>
			<section>
				<div className="container">
					<div className="row align-items-center custom-mt-12 wow fadeInRight">
						<div className="col-lg-6 col-md-12 order-lg-12">
							<div className="blink-img">
								<img
									className="img-fluid blinkblink"
									src="images/pattern/04.png"
									alt=""
								/>
							</div>
							<img
								className="img-fluid w-100"
								src="images/banner/cloud-storage.png"
								alt=""
							/>
						</div>
						<div className="col-lg-6 col-md-12 md-mt-5 order-lg-1">
							<div className="section-title mb-3">
								<div className="title-effect title-effect-2">
									<div className="ellipse"></div>{" "}
									<i className="la la-info"></i>
								</div>
								<h2 className="font-weight-bold">
									Why do you need database migration services?
								</h2>
							</div>
							<p className="lead font-weight-bold">
								It is crucial that your systems are efficient, precise, secure and reliable for your organization.
								Therefore, in order to keep up with industry and maintain a high level of performance,
								dynamically changing and upgrading your systems with us is the way to go.
							
							</p>
							<ul className="list-unstyled list-icon-2 d-inline-block font-weight-bold">
								<li>Reliable database maintenance</li>
								<br />
								<li>Full-scale service from installation to migration</li>
								<br />
								<li>Experts in many databases</li>

							</ul>
						</div>
					</div>
				</div>
			</section>
			<section className="bg-effect pos-r">
				<div className="container">
					<div className="row align-items-center custom-mt-10 wow fadeInLeft">
						<div className="col-lg-5 col-md-12 image-column">
							<div className="blink-img">
								<img
									className="img-fluid blinkblink"
									src="images/pattern/04.png"
									alt=""
								/>
							</div>
							<img
								className="img-fluid"
								src="images/banner/computer-infrastructure-2.png"
								alt=""
							/>
						</div>
						<div className="col-lg-6 col-md-12 md-mt-5 ml-auto">
							<div className="section-title mb-3">
								<div className="title-effect title-effect-2">
									<div className="ellipse"></div>{" "}
									<i className="la la-info"></i>
								</div>
								<h2 className="font-weight-bold">
									While there are some concerns with migration: 
								</h2>
							</div>
							<p className="lead">
								
							</p>
							<ul className="list-unstyled list-icon-2 d-inline-block font-weight-bold">
								<li>Switching from old technologies might be risky, time-consuming and complex</li>
								<br />
								<li>Fear of losing valuable data or disrupting regular workflows</li>
								<br />
								<li>Competitors are performing better with better technologies and resources</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
export default About;
