import React from "react";
import dataFeatures from "../../data.json";
const URL = require("../../constants");

const Features = () => {
	return (
		<>
			<section className="pos-r o-hidden wow fadeInUp text-center">
				<div className="bg-animation">
					<img
						className="zoom-fade"
						src="images/pattern/03.png"
						alt=""
					/>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-md-12 ml-auto mr-auto">
							<div className="section-title">
								<div className="title-effect title-effect-2">
									<div className="ellipse"></div>{" "}
									<i className="la la-question"></i>
								</div>
								<h2 className="title">
									Why Migrate with Us?
								</h2>
								<p className="font-weight-bold">
									Database migration can be risky, but we are here to make sure that 
									the process is a breeze so that your systems are upgraded and ready in no time
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						{dataFeatures.data.services[2].features.map(
							(feature, index) => (
								<div
									className="col-lg-4 col-md-6 mt-3"
									key={index}
								>
									<div className="featured-item style-3">
										<div className="featured-icon">
											<i className={feature["icon"]}/>
										</div>
										<div className="featured-title">
											<h5 className="font-weight-bold">{feature["title"]}</h5>
										</div>
										<div className="lead featured-desc font-weight-bold">
											<p >{feature["description"]}</p>
										</div>
									</div>
								</div>
							)
						)}
					</div>
				</div>
			</section>
		</>
	);
};
export default Features;
