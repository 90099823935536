import React from "react";
import dataFeatures from "../../../data.json";
const URL = require("../../../constants");

const Features = () => {
	return (
		<>
			<section
				className="pos-r o-hidden"
				data-bg-img="images/pattern/03.png"
			>
				<div className="container">
					<div className="row">
						<div className="col-lg-6 image-column right">
							<img
								className="img-fluid"
								src="images/banner/lab-equipment.png"
								alt=""
							/>
						</div>
						<div className="col-lg-6 col-md-12 wow fadeInLeft align-item-center">
							{dataFeatures.data.services[1].about.features.map(
								(feature, index) => (
									<div
										className="featured-item style-3 mt-2"
										key={index}
									>
										<div className="featured-icon">
											<i className={feature["icon"]}></i>
										</div>
										<div className="featured-title">
											<h5 className="font-weight-bold">{feature["title"]}</h5>
										</div>
										<div className="featured-desc">
											<p className="font-weight-bold">{feature["description"]}</p>
										</div>
									</div>
								)
							)}
						</div>
					</div>
				</div>
				<svg
					className="wave-animation"
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					viewBox="0 24 150 28"
					preserveAspectRatio="none"
				>
					<defs>
						<path
							id="gentle-wave"
							d="M-160 44c30 0 
    58-18 88-18s
    58 18 88 18 
    58-18 88-18 
    58 18 88 18
    v44h-352z"
						/>
					</defs>
					<g className="wave-bg">
						<use
							xlinkHref="#gentle-wave"
							x="50"
							y="0"
							fill="rgba(237,251,233,0.2)"
						/>
						<use
							xlinkHref="#gentle-wave"
							x="50"
							y="3"
							fill="rgba(255,204,112,0.2)"
						/>
						<use
							xlinkHref="#gentle-wave"
							x="50"
							y="6"
							fill="#ffffff"
						/>
					</g>
				</svg>
			</section>
		</>
	);
};
export default Features;
