import React from "react";
import dataAbout from "../../data.json";

const Database = () => {
	return (
	<>
		<section className="p-2"> 
			<div className="container wow fadeInRight"
			data-wow-duration="2s">
				<div className="row align-items-center">
					<div className="col-lg-6 col-md-12 p-1">
						<img
							className="img-center"
							src="images/banner/system.png"
							alt=""
							/>
					</div>
					<div className="col-lg-5 ml-auto col-md-12 md-mt-5 font-weight-bold">
						<div className="section-title mb-4">
							<div className="title-effect">
								<div className="bar bar-top"></div>
								<div className="bar bar-right"></div>
								<div className="bar bar-bottom"></div>
								<div className="bar bar-left"></div>
							</div>
							<h6>Database Maintenance</h6>
							<h2 className="font-weight-bold">
								Improving databases' performance, freeing up disk space and reduce data errors
							</h2>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section className="pos-r o-hidden text-center">
			<div className="bg-animation">
				<img
					className="zoom-fade"
					src="images/pattern/03.png"
					alt=""
				/>
			</div>
			<div className="container wow wobble">
				<div className="row">
					<div className="col-lg-12 mt-3">
						<div className="title-effect title-effect-2">
							<div className="ellipse"></div>{" "}
							<i className="la la-cubes"></i>
						</div>
						<h2>Our database Maintenance services</h2>
					</div>
				</div>
				<div className="row">
					{dataAbout.data.services[4].database.map(
						(feature, index) => (
							<div className="col-lg-4 col-md-4 mt-3" key={index}>
								<div className="featured-item style-2">
									<div className="featured-title">
										<h5 className="font-weight-bold" style={{color: feature["color"]}}>
											{feature["title"]}
										</h5>
									</div>
									<div className="featured-desc">
										<p className="font-weight-bold">{feature["description"]}</p>
									</div>
								</div>
							</div>
						)
					)}
				</div>
			</div>
		</section>
	</>
	);
};

export default Database;
