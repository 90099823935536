import React from "react";
import MainNavigation from "./components/MainNavigation";

function App() {
	return (
		<>
			<MainNavigation />
		</>
	);
}

export default App;
