import React from "react";
import dataHerosection from "../../../data.json";
const URL = require("../../../constants");

const HeroSectionTwo = () => {
	return (
		<>
			<div
				className="owl-carousel owl-theme no-pb"
				data-dots="false"
				data-items="1"
				data-autoplay="true"
			>
				<div className="item">
					<section className="fullscreen-banner banner p-0"
					data-bg-img="images/bg/stethoscope.png"
					style={{height:"100vh"}}
					>
					<div className="align-center pt-0">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-8 ">
									<h1
										className="mb-4 wow fadeInUp"
										data-wow-duration="1.5s"
										
									>
										Healthcare Information System
									</h1>
									
									<h4
										className="lead mb-4 wow fadeInUp font-weight-bold"
										data-wow-duration="2s"
										data-wow-delay="0.3s"
										style={{color: 'black', backgroundColor: 'orange'}}
									>
									Tackling healthcare's biggest challenges with modern technologies,
									producing quality solutions at an affordable price
									</h4>
									<a
										className="btn btn-theme"
										href="/health-info-system"
										data-text="Learn More"
									>
										{" "}
										<span>L</span>
										<span>e</span>
										<span>a</span>
										<span>r</span>
										<span>n</span>
										<span></span>{" "}
										<span> </span>
										<span>M</span>
										<span>o</span>
										<span>r</span>
										<span>e</span>
										<div className="spinner-eff">
											<div className="square"></div>		
										</div>
									</a>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				<div className="item">
					<section className="fullscreen-banner banner p-0"
					data-bg-img="images/bg/hospital-machine.png"
					style={{height:"100vh"}}>
						<div className="align-center pb-3">
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-8">
										<h1
											className="mb-4 wow fadeInUp"
											data-wow-duration="1.5s"
										
										>
										Hospital Machine & System Integration
										</h1>
										<h4
											className="lead mb-4 wow fadeInUp font-weight-bold"
											data-wow-duration="2s"
											data-wow-delay="0.3s"
											style={{color: 'black', backgroundColor: 'orange'}}
										>
											Improving productivity by
											removing the need to manually transcribe results from machines with higher data
											accuracy.
										</h4>
										<a
											className="btn btn-theme"
											href="/machine-integration"
											data-text="Learn More"
										>
											{" "}
										<span>L</span>
										<span>e</span>
										<span>a</span>
										<span>r</span>
										<span>n</span>
										<span></span>{" "}
										<span> </span>
										<span>M</span>
										<span>o</span>
										<span>r</span>
										<span>e</span>
											<div className="spinner-eff">
												<div className="square"></div>
											</div>
										</a>
									</div>
									</div>
								</div>
							
						</div>
					</section>
				</div>
			</div>
		</>
	);
};

export default HeroSectionTwo;
