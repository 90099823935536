import React from "react";
import Header from "../../Header1";
import HeroSection from "./HeroSection";
import Contact from "./Contact";
import Database from "./Database";
import Hardware from "./Hardware";
import Footer from "../../Footer2";
import Features from "./Features";
const Maintenance = () => {
    return(
        <>
        <Header />
        <div className="page-content">
            <HeroSection />
            <Database />
            <Hardware />
            <Features />
            <Contact />
        </div>
            <Footer />
        </>
    )
}

export default Maintenance;