import React from "react";
import Header from "../../Header1";
import Footer from "../../Footer2";
import Title from "./Title";
import About from "./About";
import Services from "./Pricing";
import Contact from "./Contact";

const AboutOne = () => {
	return (
		<>
			<Header />
			<div className="page-wrapper">
				<Title />
				<div className="page-content">
					<About />
					<Services />
					<Contact />
				</div>
			</div>
			<Footer />
		</>
	);
};
export default AboutOne;
