import React from "react";
import {Message} from "react";
import dataContact from "../../data.json";
import { useForm } from "react-hook-form";
import emailjs from 'emailjs-com';

const Form = () => {
	const { register, handleSubmit, errors } = useForm();
	function onSubmit(data,e){
		emailjs.send('service_yrekrgr', 'template_dgfi28d', data, 'user_Hx2rxmARwqDfuxK8kLRYa')
		.then((result) => {
			alert("Success!")
		}, (error) => {
			alert("Failed! Please try again")
		});
		e.target.reset();

	}
	return (
		<>
			<section className="contact-1 o-hidden pt-10">
			<div className="page-title-pattern">
				<img className="img-fluid" src="images/bg/02.png" alt="" />
			</div>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 col-md-12 wow fadeInRight order-lg-2">
							<img
								className="img-center"
								src="images/banner/contact-pic.png"
								alt=""
							/>
						</div>
						<div className="col-lg-6 col-md-12 md-mt-5 wow fadeInLeft order-lg-1">
							<div className="section-title">
								<div className="title-effect">
									<div className="bar bar-top"></div>
									<div className="bar bar-right"></div>
									<div className="bar bar-bottom"></div>
									<div className="bar bar-left"></div>
								</div>
								<h6>
									Stay in touch
								</h6>
								<h2>
									Submit your details here.
								</h2>
								<p>
									We will be in touch as soon as possible.
								</p>
							</div>
							<form
								id="contact-form"
								onSubmit={handleSubmit(onSubmit)}
								//method="POST"
							>	
								<div className="messages"></div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
										<label htmlFor="firstName">
												First Name
											</label>
											{errors.firstName && (
												<span className="help-block with-errors">
													Firstname is required.
												</span>
											)}

											<input
												id="form_name"
												type="text"
												name="firstName"
												className="form-control"
												placeholder="Type First name"
												ref={register({
													required: "Required",
												})}
											/>

										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<label htmlFor="lastName">
												Last Name
											</label>
											{errors.lastName && (
												<span className="help-block with-errors">
													Lastname is required.
												</span>
											)}
											<input
												id="form_lastname"
												type="text"
												name="lastName"
												className="form-control"
												placeholder="Type Last name"
												ref={register({
													required: "Required",
												})}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<label htmlFor="email">
												Email Address
											</label>
											{errors.email && (
												<span className="help-block with-errors">
													Valid email is required.
												</span>
											)}
											<input
												id="form_email"
												type="email"
												name="email"
												className="form-control"
												placeholder="Type Email"
												ref={register({
													required: "Required",
													pattern: {
														value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
													},
												})}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<label>Phone Number</label>
											{errors.phone && (
												<span className="help-block with-errors">
													Valid phone required (10
													digits)
												</span>
											)}

											<input
												id="form_phone"
												type="tel"
												name="phone"
												className="form-control"
												placeholder="Type Phone"
												ref={register({
													required: "Required",
													pattern: {
														value: /^\d{10}$/,
													},
												})}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group">
											<label htmlFor="organization">
												Organization
											</label>
											{errors.lastName && (
												<span className="help-block with-errors">
													Organization is required.
												</span>
											)}
											<input
												id="form_organization"
												type="text"
												name="organization"
												className="form-control"
												placeholder="Type Organization"
												ref={register({
													required: "Required",
												})}
											/>
										</div>
									</div>
														
								</div>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group">
										<label>Message</label>
											{errors.message && (
												<span className="help-block with-errors">
													Please,leave us a message.
												</span>
											)}
											<textarea
												id="form_message"
												name="message"
												className="form-control"
												placeholder="Type Message"
												rows="4"
												ref={register({
													required: "Required",
												})}
											></textarea>		
										</div>
									</div>
									<div className="col-md-12 mt-1">
										<button
											className="btn btn-theme"
											data-text="Send Message"
											type="submit"
											
										>
											<span>S</span>
											<span>e</span>
											<span>n</span>
											<span>d</span>
											<span> </span> <span> </span>
											<span>M</span>
											<span>e</span>
											<span>s</span>
											<span>s</span>
											<span>a</span>
											<span>g</span>
											<span>e</span>
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			<section className="contact-info p-0 z-index-1">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-4 col-md-12">
							<div className="contact-media">
								{" "}
								<i className="flaticon-paper-plane"></i>
								<span>Address:</span>
								<p>
									{
										dataContact.data.contact[0].details
											.address
									}
								</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 md-mt-5">
							<div className="contact-media">
								{" "}
								<i className="flaticon-email"></i>
								<span>Email Address</span>
								<a
									href={`mailto:${dataContact.data.contact[0].details.email}`}
								>
									{" "}
									{dataContact.data.contact[0].details.email}
								</a>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 md-mt-5">
							<div className="contact-media">
								{" "}
								<i className="flaticon-social-media"></i>
								<span>Phone Number</span>
								<a
									href={`mailto:${dataContact.data.contact[0].details.phone}`}
								>
									{dataContact.data.contact[0].details.phone}
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="o-hidden p-0 custom-mt-10 z-index-0">
				<div className="container-fluid p-0">
					<div className="row align-items-center">
						<div className="col-md-12">
							<div className="map iframe-h-2">
								<iframe
									title="GoogleMap"
									src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Block%20D,Plaza%20Paragon%20Point,%20Jalan%20Medan%20Pusat%20Bandar%205,%2043650%20Bandar%20Baru%20Bangi,%20Selangor%20Darul%20Ehsan.+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
									allowfullscreen
								></iframe>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Form;
