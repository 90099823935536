import React from "react";
import dataAbout from "../../../data.json";

const Features = () =>{
    return (
        <>
        <section className="pos-r px-3 pb-1">
			<div className="container">
				<div className="row align-items-center custom-mt-8 wow fadeInRight">
					<div className="col-lg-6 col-md-10 p-1 pr-lg-2">
                        <div className="section-title mb-4">
							<div className="title-effect">
								<div className="bar bar-top"></div>
								<div className="bar bar-right"></div>
								<div className="bar bar-bottom"></div>
								<div className="bar bar-left"></div>
							</div>
							<h6>Database Design</h6>
							<h2>
								Leveraging our Database expertise
							</h2>
                            <br />
                            <ul className="list-unstyled list-icon mb-4">
                                <li className="mb-3">
                                    <i className="la la-angle-right"></i>{" "}
                                    <p className="font-weight-bold" style={{textTransform: 'initial'}}>
                                        Our database specialist is a seasoned industry expert with a comprehensive understanding of the HIS workflow
                                        thus providing a top-notch database consultation experience
                                    </p>
                                </li>
						    </ul>
						</div>
					</div>
                    <div className="col-lg-6 ml-auto col-md-12 md-mt-5">
                        <img
						    className="img-fluid"
							src="images/banner/database-design.png"
							alt=""
						/>
                    </div>
				</div>
			</div>
		</section>

        <section className="bg-effect pos-r px-2">
			<div className="container">
				<div className="row align-items-center custom-mt-8 wow fadeInLeft">
					<div className="col-lg-6 col-md-12 p-1 pr-lg-2 ">
                        <img
						    className="img-fluid"
							src="images/banner/user-experience.png"
							alt=""
						/>
					    </div>
                    <div className="col-lg-6 ml-auto col-md-10 md-mt-5">
                        <div className="section-title mb-3">
							<div className="title-effect">
								<div className="bar bar-top"></div>
								<div className="bar bar-right"></div>
								<div className="bar bar-bottom"></div>
								<div className="bar bar-left"></div>
							</div>
							<h6>Interface Design</h6>
							<h2>
								Improving user experience through extensive interface review
							</h2>
                            <br />
                            <ul className="list-unstyled list-icon mb-4">
                                <li className="mb-3">
                                    <i className="la la-angle-right"></i>{" "}
                                    <p className="font-weight-bold" style={{textTransform: 'initial'}}>
                                        We work with clients on improving the overall user experience of the HIS by focusing on
                                        user-centric design approaches, factoring accessibility, reliability and performance page by page.
                                    </p>
                                </li>
						    </ul>
						</div>
                    </div>
				</div>
			</div>
		</section>

        <section className="pos-r px-2 pt-2 pb-4">
			<div className="container">
				<div className="row align-items-center wow fadeInRight">
					<div className="col-lg-6 col-md-12 p-1 pr-lg-2">
                        <div className="section-title mb-4">
							<div className="title-effect">
								<div className="bar bar-top"></div>
								<div className="bar bar-right"></div>
								<div className="bar bar-bottom"></div>
								<div className="bar bar-left"></div>
							</div>
							<h6>Future Cost Analysis</h6>
							<h2>
								Utilizing engineering principles to analyze cost-benefit of design changes
							</h2>
                            <br />
                            <ul className="list-unstyled list-icon mb-4">
                                <li className="mb-3">
                                    <i className="la la-angle-right"></i>{" "}
                                    <p className="font-weight-bold" style={{textTransform: 'initial'}}>
                                       We perform a comprehensive analysis of the overall system and identify possible
                                       issues such as performance, data security and user experience when proposing a
                                       change.
                                    </p>
                                </li>
						    </ul>
						</div>
					</div>
                    <div className="col-lg-6 ml-auto col-md-12 md-mt-5">
                        <img
						    className="img-fluid img-shadow"
							src="images/banner/cost-analysis.png"
							alt=""
						/>
                    </div>
				</div>
			</div>
		</section>
        </>
    )
}

export default Features;
