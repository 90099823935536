import React from "react";
import Header from "../../Header1";
import Footer from "../../Footer2";
import HeroSection from "./HeroSection";
import About from "./About";
import Team from "./Team";
import Testimonial from "./Testimonial";
import Blog from "./Blog";
import PriceTable from "./PriceTable";
import Counter from "./Counter";
import Steps from "./Steps";
import Contact from "./Contact";
import Tech from "./Tech";

const SoftwareDevelopment = () => {
	return (
		<>
			<Header />
			<div className="page-content">
				<HeroSection />
				<About />
				<Tech />
				<Steps />
				<Contact />
			</div>
			<Footer />
		</>
	);
};
export default SoftwareDevelopment;
