import React from "react";
import dataServices from "../../data.json";
const URL = require("../../constants");


const Services = () => {
    return(
        <>
        <section className="pos-r o-hidden wow fadeInRight text-center">
				<div className="bg-animation">
					<img
						className="zoom-fade"
						src="images/pattern/03.png"
						alt=""
					/>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-md-12 ml-auto mr-auto">
							<div className="section-title">
								<div className="title-effect title-effect-2">
									<div className="ellipse"></div>{" "}
									<i className="la la-exclamation"></i>
								</div>
								<h2 className="title">
									Our Migration Services
								</h2>
								<p className="font-weight-bold">
									We offer different types of database migration so that we can serve businesses of 
									all sizes and industries
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						{dataServices.data.services[2].services.map(
							(feature, index) => (
								<div
									className="col-lg-3 col-md-6 mt-3"
									key={index}
								>
									<div className="featured-item style-2">
										<div className="featured-icon">
                                            <img 
                                            className="img-fluid h-100 w-100"
											src={`${URL.img_url}${feature["img_src"]}`}
											alt=""/>
										</div>
										<div className="featured-title">
											<h5 className="font-weight-bold">{feature["title"]}</h5>
										</div>
									</div>
								</div>
							)
						)}
					</div>
				</div>
			</section>
        </>
    );
};

export default Services;