import React from "react";
import dataAbout from "../../data.json";

const About = () => {
	return (
		<section className="text-center p-0 pos-r" data-bg-img="images/pattern/01.png">
			<div className="container">
				<div className="row custom-mt-10">
					<div className="col-lg-10 col-md-12 ml-auto mr-auto">
					<img className="img-fluid" src="images/mackbook/01.png" alt="macbook"
					/>
						<h3 className="text-black font-weight-bold line-h-3 wow fadeInUp">
							We use {" "}
							<span className="text-theme">cutting edge solutions</span> {" "}
							and <span className="text-theme">innovative development</span> {" "}
							to build fully customized software solutions
						</h3>
					</div>
				</div>
				<div className="row text-center wow fadeInUp">
					{dataAbout.data.services[3
					].about.features.map(
						(feature, index) => (
							<div className="col-lg-4 col-md-6 mt-5" key={index}>
								<div className="featured-item style-1">
									<div className="featured-icon">
										<i className={`${feature["icon"]}`}></i>
									</div>
									<div className="featured-title font-weight-bold">
										<h5>{feature["title"]}</h5>
									</div>
									<div className="featured-desc font-weight-bold">
										<p>{feature["description"]}</p>
									</div>
								</div>
							</div>
						)
					)}
				</div>
			</div>
		</section>
	);
};

export default About;
