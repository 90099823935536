import React from "react";
import dataContact from "../../data.json";
const URL = require("../../constants");

const Contact = () => {
    return (
        <section className="theme-bg pos-r o-hidden">
            <div className="container wow fadeInUp">
                <div className="row text-center">
                    <div className="col-lg-8 col-md-12 ml-auto mr-auto">
                    <div className="section-title">
						<div className="title-effect title-effect-2">
							<div className="ellipse"></div>{" "}
							<i className="la la-info"></i>
						</div>
        		        <h1 className="title text-white">
                        Ready to Get in Touch?
						</h1>
                        <br />
						<a
									className="btn btn-white"
									data-wow-duration="3s"
									data-wow-delay="0.5s"
									href="/contact"
									data-text="Contact Us"
								>
									{" "}
									<span>C</span>
									<span>o</span>
									<span>n</span>
									<span>t</span>
									<span>a</span>
									<span>c</span>
									<span>t</span>
									<span> </span>{" "}
									<span>U</span>
									<span>s</span>
                                    
                                    
									<div className="spinner-eff">
											<div className="square"></div>
										
									</div>
								</a>
					</div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;