import React from "react";

const Title = () => {
	return (
		<section
			className="fullscreen-banner transparent-bg banner p-0 " 
			data-bg-img="images/banner/kuala-lumpur-8.png"
			style={{height: "100vh"}}
		
		>
			<div className="align-center p-0">
				<div className="container">
					<div className="row align-items-center wow fadeInDown">
						<div className="col-lg-10 order-lg-1 md-mt-5">
							<h4	classname="font-weight-bold" data-wow-duration="1.5s" style={{color: 'black', backgroundColor: 'orange'}}>
								ABOUT SUREZE
							</h4>
							<br/>
							<h5 classname="font-weight-bold" style={{ color: 'white', textTransform: 'initial'}}>
								Sureze is a software development company headquartered in Malaysia. 
								For the last few years,
								we have been guiding companies and organizations in reimagining their business by
								engineering quality digital solutions using the latest technologies.
							</h5>
							</div>
						</div>
				</div>
			</div>
		</section>
	);
};

export default Title;
