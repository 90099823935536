import React from "react";
import Features from "./Features";
import Header from "../../Header1";
import Footer from "../../Footer2";
import About from "./About";
import Steps from "./Steps";
import HeroSection from "./HeroSection";
import Contact from "./Contact";
import Services from "./Services";
import Tech from "./Tech";

const PageContent = () => {
	return (
		<>
			<Header />
			<div className="page-content">
				<HeroSection />
				<Services />
				<About />
				<Features />
				<Steps />
				<Tech />
				<Contact />
			</div>
			<Footer />
		</>
	);
};
export default PageContent;
