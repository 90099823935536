import React from "react";
import dataPricing from "../../data.json";

const Pricing = () => {
	return (
		<section className="dark-bg">
			<div className="container">
				<div className="row text-center wow fadeInRight">
					<div className="col-lg-12">
						<div className="section-title">
							<div className="title-effect">
								<div className="bar bar-top"></div>
								<div className="bar bar-right"></div>
								<div className="bar bar-bottom"></div>
								<div className="bar bar-left"></div>
							</div>
							<h6 className="font-weight-bold">{dataPricing.data.about[0].pricing.title}</h6>
							<h2 className="title font-weight-bold" style={{color: 'black'}}>
								{dataPricing.data.about[0].pricing.sub_title}
							</h2>
						</div>
					</div>
				</div>
				<div className="row align-items-center wow fadeInLeft">
					{dataPricing.data.about[0].pricing.plan.map(
						(plan, index) => (
							<div
								className="col-lg-3 col-md-8 md-mt-5 ml-auto mr-auto"
								key={index}
							>
								<div className="price-table style-2">
									<div className="price-inside">
										{plan["name"]}
									</div>
									<div className="price-header">
										<h4 className="price-title" style={{color: "#1f4e79"}}>
											{plan["title"]}
										</h4>
									</div>
									<br />
									<div className="price-list ">
										<ul className="list-unstyled">
											{plan.features.map(
												(feature, index) => (
													<li key={index}>
														<p className="font-weight-bold " style={{color: 'grey'}}>{feature}</p>
													</li>
												)
											)}
										</ul>
									</div>
									<a
										className="btn btn-theme btn-circle my-4"
										href={plan["link"]}
										data-text="Learn More"
									>
										{" "}
										<span>L</span>
										<span>e</span>
										<span>a</span>
										<span>r</span>
										<span>n</span> 
										<span> </span> {" "}
										<span>M</span>
										<span>o</span>
										<span>r</span>
										<span>e</span>
									
									</a>
								</div>
							</div>
						)
					)}
				</div>
			</div>
		</section>
	);
};

export default Pricing;
