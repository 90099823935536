import React, { useState } from "react";
import Loader from "./Loader";
import dataHeader from "./data.json";
const URL = require("./constants");

const Header1 = () => {
	console.log(dataHeader.data.home[0]);
	const storedPage = localStorage.getItem("page");
	const [breadcrumb] = useState(storedPage);
	const fun = (value) => {
		localStorage.setItem("page", value);
	};
	return (
		<>
			<Loader />
			<header id="site-header" className="header header-2">
				<div className="container-fluid">
					<div id="header-wrap" className="font-weight-bold">
						<div className="row">
							<div className="col-xl-10 col-lg-10 col-md-10 ml-auto mr-auto">
								{/* Navbar */}
								<nav className="navbar navbar-expand-lg">
									<a className="navbar-brand logo" href="/">
										
										<img
											id="logo-img"
											className="img-center"
											onClick={() =>
												fun(
													"home"
												)
											}
											src={`${URL.img_url}${dataHeader.data.home[0].logo}`}
											alt=""
										/>
									</a>
									<button
										className="navbar-toggler"
										type="button"
										data-toggle="collapse"
										data-target="#navbarNavDropdown"
										aria-expanded="false"
										aria-label="Toggle navigation"
									>
										{" "}
										<span></span>
										<span></span>
										<span></span>
									</button>
									<div
										className="collapse navbar-collapse font-weight-bold"
										id="navbarNavDropdown"
									>
										{/* Left nav */}
										<ul
											id="main-menu"
											className="nav navbar-nav ml-auto mr-auto pl-5"
										>
											<li className="nav-item">
												{" "}
												<a
													className={`nav-link ${
														breadcrumb === "home"
															? "active"
															: ""
													} font-weight-bold`}
													
													href="/#"
													
													onClick={() =>
														fun(
															"home"
														)
													}
												>
													HOME
												</a>				
											</li>
											<li className="nav-item">
												{" "}
												<a
													className={`nav-link ${
														breadcrumb === "service"
															? "active"
															: ""
													} font-weight-bold`}
												
												
												onClick={() =>
													fun(
														"service"
													)
												}
												href="#"
												>
													SERVICES
												</a>
												
												<ul>
													<li>
														<a href="software-development"
														onClick={() =>
															fun(
																"service"
															)
														}
														className="font-weight-bold"
														>Software Development</a>
													</li>
													<li>
														<a href="/healthcare-solutions" 
														onClick={() =>
														fun(
															"service"
														)
														
												}
												className="font-weight-bold"
												>Healthcare Solutions</a>
														<ul>
															<li>
																<a href="health-info-system"
																onClick={() =>
																	fun(
																		"service"
																	)
																}
																className="font-weight-bold"
																>Health Information System</a>
																<a href="machine-integration"
																onClick={() =>
																	fun(
																		"service"
																	)
																}
																className="font-weight-bold"
																>Machine Integration</a>
															</li>
														</ul>
													</li>
													<li>
														<a href="database-migration"
														onClick={() =>
															fun(
																"service"
															)
														}
														className="font-weight-bold"
														>Database Migration</a>
													</li>
													<li>
														<a href="maintenance"
														onClick={() =>
															fun(
																"service"
															)
														}
														className="font-weight-bold"
														>Support & Maintenance</a>
													</li>
												</ul>
											</li>
											<li className="nav-item">
											{" "}
												<a
													className={`nav-link ${
														breadcrumb === "about"
															? "active"
															: ""
													} font-weight-bold`}
													
													href="/about"
													onClick={() =>
														fun(
															"about"
														)
													}
												>
													ABOUT
												</a>
											</li>
											<li className="nav-item">
												{" "}
												<a
													className={`nav-link ${
														breadcrumb === "contact"
															? "active"
															: ""
													} font-weight-bold`}
													href="/contact"
													onClick={() =>
														fun(
															"contact"
														)
													}
													
													
												>
													CONTACT US
												</a>
											</li>
										</ul>
									
									</div>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
};

export default Header1;
