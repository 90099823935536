import React from "react";
import Data from "../../data.json";


const Features = () =>{
    let counter = 1;
    return(
        <>
            <section className="banner"
            data-bg-img="images/bg/06.png">
				<div className="container">
					<div className="row">
                        <div className="col-lg-10 col-md-8 ml-auto mr-auto text-center">
						    <div className="section-title">
							    <div className="title-effect title-effect-2">
							    	<div className="ellipse"></div>{" "}
								    <i className="la la-gear"></i>
							    </div>
							    <h2 className="title">
                                  Why Choose Us?
							    </h2>
						    </div>
					    </div>
						<div className="col-lg-6">
							<div id="accordion" className="accordion style-1 align-items-center">
								{Data.data.element[0].questions.map(
									(data, index) => (
										<div
											className={`card ${
												counter === 1 ? "active" : ""
											}`}
											key={index}
										>
											<div className="card-header">
												<h3 className="font-weight-bold">
													<a
                                                        className="font-weight-bold"
														data-toggle="collapse"
														data-parent="#accordion"
														href={`#collapse${counter}`}
														aria-expanded="true"
													>
                                                        {data["title"]}
													</a>
												</h3>
											</div>
											<div
												id={`collapse${counter}`}
												className={`collapse ${
													counter++ === 1
														? "show"
														: ""
												}`}
												data-parent="#accordion"
											>
												<div className="card-body">
                                                    <p className="font-weight-bold">{data["content"]}</p>
                                                    <img className="img-fluid ml-auto mr-auto" src={data["img-src"]}/>
												</div>
											</div>
										</div>
									)
								)}
							</div>
						</div>
                        <div className="col-lg-6 col-md-4">
                            <img className="img-fluid" src="images/banner/developer-2.png"/>
                        </div>
					</div>
				</div>
			</section>
        </>
    )

}

export default Features;