import React from "react";
import dataContact from "../../../data.json";
const URL = require("../../../constants");

const Contact = () => {
    return (
        <section className="theme-bg pos-r o-hidden">
            <div className="container wow fadeInUp">
                <div className="row text-center">
                    <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                    <div className="section-title">
						<div className="title-effect title-effect-2">
							<div className="ellipse"></div>{" "}
							<i className="la la-info"></i>
						</div>
        		        <h1 className="title">
                        Let's start building your solutions
						</h1>
                        <br />
						<a
									className="btn btn-white"
									data-wow-duration="3s"
									data-wow-delay="0.5s"
									href="/contact"
									data-text="Get In Touch With Us"
								>
									{" "}
									<span>G</span>
									<span>e</span>
									<span>t</span>
									<span> </span>{" "}
									<span>I</span>
									<span>n</span>
									<span> </span>{" "}
									<span>T</span>
									<span>o</span>
									<span>u</span>
                                    <span>c</span>
                                    <span>h</span>
                                    <span> </span>{" "}
                                    <span>W</span>
                                    <span>i</span>
                                    <span>t</span>
                                    <span>h</span>
                                    <span> </span>{" "}
                                    <span>U</span>
                                    <span>s</span>
                                    
									<div className="spinner-eff">
											<div className="square"></div>
										
									</div>
								</a>
					</div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;