import React from "react";
import dataAbout from "../../../data.json";

const AboutTwo = () => {
	return (
		<section className="pos-r py-5 dark-bg" id="consult">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6 col-md-12 p-1 pr-lg-2 wow fadeInLeft">
							<img
								className="img-fluid"
								src="images/banner/doctor-patient.png"
								alt=""
							/>
					</div>
					<div className="col-lg-6 ml-auto mr-auto col-md-12 md-mt-5 col-lg-2wow fadeInRight">
						<div className="section-title mb-4">
							<div className="title-effect">
								<div className="bar bar-top"></div>
								<div className="bar bar-right"></div>
								<div className="bar bar-bottom"></div>
								<div className="bar bar-left"></div>
							</div>
							<h6>HIS Consultation</h6>
							<h2>
								Providing in-depth consultation for your HIS implementation
							</h2>
						</div>
						<ul className="custom-li list-unstyled list-icon-2 d-inline-block">
							{dataAbout.data.services[0].about.consult.highlights.map(
								(feature, index) => (
									<li className="mb-3" key={index}>
										<p className="font-weight-bold">{feature}</p>
									</li>
								)
							)}
						</ul>
						<a
							className="btn btn-theme btn-circle"
							href="/contact"
							data-text="Consult With Us"
						>
							{" "}
							<span>C</span>
							<span>o</span>
							<span>n</span>
							<span>s</span>
							<span>u</span>
							<span>l</span>
							<span>t</span>
							<span> </span>{" "}
							<span>W</span>
							<span>i</span>
							<span>t</span>
							<span>h</span>
							<span> </span>{" "}
							<span>U</span>
							<span>s</span>

						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutTwo;
