import React from "react";
//import dataHerosection from "../../../data.json";


const HeroSectionFour = () => {
	return (
		<>
			{/* hero section :: start */}
			<section className="fullscreen-banner banner p-0 dark-bg">
				<div className="h-100">
					<div className="align-center pt-0">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-6 col-md-12 order-lg-1">
									<div
										className="owl-carousel owl-theme no-pb"
										data-dots="false"
										data-items="1"
										data-autoplay="true"
									>
										<div className="item">
											<div className="img-box">
												<div className="box-loader">
													{" "}
													<span></span>
													<span></span>
													<span></span>
												</div>
												<img
													className="img-center"
													src="images/banner/03.jpg"
													alt=""
												/>
											</div>
										</div>
										<div className="item">
											<div className="img-box">
												<div className="box-loader">
													{" "}
													<span></span>
													<span></span>
													<span></span>
												</div>
												<img
													className="img-center"
													src="images/banner/04.jpg"
													alt=""
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-12 md-mt-5 order-lg-1 wow fadeInUp">
									<h1
										className="mb-4"
										data-wow-duration="1.5s"
										style={{color: '#1F4E79'}}
									>
										An efficient and user-friendly system for you.
									</h1>
									<h4
										className="lead mb-5 font-weight-bold"
										data-wow-duration="2s"
										data-wow-delay="0.3s"
										style={{color: 'white', backgroundColor: '#1F4E79'}}
									>
										We use the latest technologies and user experience focused development principles to develop interfaces for medical experts, staff and patients.
									</h4>
									<a
									className="btn btn-theme"
									data-wow-duration="3s"
									data-wow-delay="0.5s"
									href="#development"
									data-text="Development"
								>
									{" "}
									<span>D</span>
									<span>e</span>
									<span>v</span>
									<span>e</span>
									<span>l</span>
									<span>o</span>
									<span>p</span>
									<span>m</span>
									<span>e</span>
									<span>n</span>
									<span>t</span>
								</a>
								<a
									className="btn btn-white"
									data-wow-duration="3s"
									data-wow-delay="0.5s"
									href="#consult"
									data-text="Consultation"
								>
									{" "}
									<span>C</span>
									<span>o</span>
									<span>n</span>
									<span>s</span>
									<span>u</span>
									<span>l</span>
									<span>t</span>
									<span>a</span>
									<span>t</span>
									<span>i</span>
									<span>o</span>
									<span>n</span>
								</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<svg
					className="wave-animation"
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					viewBox="0 24 150 28"
					preserveAspectRatio="none"
				>
					<defs>
						<path
							id="gentle-wave"
							d="M-160 44c30 0 
    58-18 88-18s
    58 18 88 18 
    58-18 88-18 
    58 18 88 18
    v44h-352z"
						/>
					</defs>
					<g className="wave-bg">
						<use
							xlinkHref="#gentle-wave"
							x="50"
							y="0"
							fill="#1F4E79"
						/>
						<use
							xlinkHref="#gentle-wave"
							x="50"
							y="3"
							fill="rgba(255,204,112,0.2)"
						/>
						<use
							xlinkHref="#gentle-wave"
							x="50"
							y="6"
							fill="#ffffff"
						/>
					</g>
				</svg>
			</section>
		</>
	);
};

export default HeroSectionFour;
