import React from "react";
import dataHerosection from "../../data.json";

const HeroSectionThree = () => {
	return (
		<>
			{/* hero section :: start */}
			<section
				className="fullscreen-banner banner bg-contain p-0"
				data-bg-img="images/pattern/01.png"
				data-bg-color="#fbfbfb"
			>
				<div className="align-center pt-0">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 col-md-12 order-lg-2">
								<img
									className="img-center wow tada"
									data-wow-duration="2s"
									src="images/banner/developer-women.png"
									alt=""
								/>
							</div>
							<div className="col-lg-6 col-md-12 md-mt-5">
								<h5
									className=" wow fadeInDown"
									data-wow-duration="1.5s"
									style={{color: 'white'}}
								>
									Software Development
								</h5>
								<h1
									className="mb-4 wow jackInTheBox"
									data-wow-duration="2s"
								>
									Quality Software{" "}
									<span className="text-theme">
										For your Digital Business
									</span>
								</h1>
								<p
									className="lead mb-0 wow fadeInUp font-weight-bold"
									data-wow-duration="2s"
									data-wow-delay="0.3s"
								>
									Through innovative development and client-focused design principles, we can
									leverage your organization's digital present to a new level
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default HeroSectionThree;
