import React from "react";
import dataAbout from "../../../data.json";

const About = () => {
	return (
		<>
			<section className="wow fadeInLeft">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 col-md-12 order-lg-12">
							<div className="blink-img">
								<img
									className="img-fluid blinkblink"
									src="images/pattern/04.png"
									alt=""
								/>
							</div>
							<img
								className="img-fluid w-100"
								src="images/banner/healthcare-record-2.png"
								alt=""
							/>
						</div>
						<div className="col-lg-6 col-md-12 md-mt-5 order-lg-1">
							<div className="section-title mb-3">
								<div className="title-effect title-effect-2">
									<div className="ellipse"></div>{" "}
									<i className="la la-info"></i>
								</div>
								<h2>
									Transforming hospital workflow and improving patient care
								</h2>
							</div>
							<p className="lead font-weight-bold">
								We enable medical facilities to achieve operational efficiency by providing
								fully customizable HIS systems and in-depth consultation
							</p>
							<ul className="custom-li list-unstyled list-icon-2 d-inline-block font-weight-bold">
								<li>
									Comprehensive
								</li>
								<li>
									Fully Customizable
								</li>	
								<li>
									Reliable
								</li>
								<li>
									User-friendly
								</li>
								<li>
									Scalable
								</li>
								<li>
									Support Services
								</li>
							</ul>
							<a
								className="btn btn-theme btn-circle"
								href="/health-info-system"
								data-text="Learn More"
							>
								{" "}
								<span>L</span>
								<span>e</span>
								<span>a</span>
								<span>r</span>
								<span>n</span>
								<span> </span>{" "}
								<span>M</span>
								<span>o</span>
								<span>r</span>
								<span>e</span>
							</a>
						</div>
					</div>
				</div>
			</section>
			<section className="bg-effect pos-r wow fadeInRight">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-5 col-md-12">
							<div className="blink-img">
								<img
									className="img-fluid blinkblink"
									src="images/pattern/04.png"
									alt=""
								/>
							</div>
							<img
								className="img-fluid"
								src="images/banner/lab-machine.png"
								alt=""
							/>
						</div>
						<div className="col-lg-6 col-md-12 md-mt-5 ml-auto">
							<div className="section-title mb-3">
								<div className="title-effect title-effect-2">
									<div className="ellipse"></div>{" "}
									<i className="la la-info"></i>
								</div>
								<h2>
									Integrating hospital machines to HIS
									efficiently
								</h2>
							</div>
							<p className="lead font-weight-bold">
								We manage connectivity to third-party systems and equipments 
								using HL7, ASTM, XML, JSON, DICOM, FHIR, and more
							</p>
							<ul className="custom-li list-unstyled list-icon-2 d-inline-block font-weight-bold">
								<li>
									Quick Upload Automation to HIS
								</li>
								<li>
									Significantly reduces human errors
								</li>
								<li>
									Interface with diverse hospital equipments
								</li>	
							</ul>
							<a
								className="btn btn-theme btn-circle"
								href="/machine-integration"
								data-text="Learn More"
							>
								{" "}
								<span>L</span>
								<span>e</span>
								<span>a</span>
								<span>r</span>
								<span>n</span>
								<span> </span>{" "}
								<span>M</span>
								<span>o</span>
								<span>r</span>
								<span>e</span>
							</a>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
export default About;
