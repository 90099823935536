import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Home from "./home/homeOne";
import Error404 from "./pages/error404";
import HealthInfoSystem from "./services/healthcare-solutions/his";
import MachineIntegration from "./services/healthcare-solutions/integration";
import HealthcareSolutions from "./services/healthcare-solutions";
import About from "./about/aboutOne";
import DatabaseMigration from "./services/database-migration";
import SoftwareDevelopment from "./services/outsource-dev";
import Contact from "./contact/contactTwo";
import Privacy from "./policy/privacy";
import Terms from "./policy/terms";
import Maintenance from "./services/maintenance";

function MainNavigation() {
  return (
    <Router>
      <Switch>
        {/** Home */}
        <Route path="/" exact={true} component={Home} />
        {/** Pages */}
        <Route path="/error404" exact={true} component={Error404} />
        <Route path="/about" exact={true} component={About} />
        {/** Services **/}
        <Route path="/machine-integration" exact={true} component={MachineIntegration} />
        <Route path="/health-info-system" exact={true} component={HealthInfoSystem} />
        <Route path="/software-development" exact={true} component={SoftwareDevelopment} />
        <Route path="/database-migration" exact={true} component={DatabaseMigration} />
        <Route path="/healthcare-solutions" exact={true} component={HealthcareSolutions} />
        <Route path="/maintenance" exact={true} component={Maintenance} />
        <Route path="/contact" exact={true} component={Contact} />
        {/** Policy */}
        <Route path="/privacy" exact={true} component={Privacy} />
        <Route path="/terms" exact={true} component={Terms} />
        {/** invalid routes redirection */}
        <Route component={Error404} />
      </Switch>
    </Router>
  );
}

export default MainNavigation;
