import React from "react";
import dataHerosection from "../../../data.json";
const URL = require("../../../constants");

const HeroSectionOne = () => {
	return (
		<>
			{/* hero section :: start */}
			<section
				className="fullscreen-banner banner p-0"
				data-bg-img="images/banner/doctor-4.png"
				style={{height: "100vh"}}
				
			>
				 <div className="align-right">
					<div className="container">
						<div className="row" >
							<div className="page-title">
								<div className="col-lg-10 col-md-8 wow fadeInLeft"  data-wow-duration="2s">
								<img className="img-fluid" src="images/banner/tag-line-3.png"/>
									<br />
									<a
										className="btn btn-theme" 
										href="/contact"
										data-text="Contact Us"
									>
										{" "}
										<span>C</span>
										<span>o</span>
										<span>n</span>
										<span>t</span>
										<span>a</span>
										<span>c</span>
										<span>t</span>
										<span> </span>{" "}
										<span>U</span>
										<span>s</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div> 
			</section>
		</>
	);
};

export default HeroSectionOne;
