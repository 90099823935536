import React from "react";
import dataSteps from "../../../data.json"

const Steps = () => {
	return (
		<>
			<section
				className="transparent-bg pos-r o-hidden text-center"
				data-bg-img="images/pattern/03.png"
			>
				<div className="container wow fadeInLeft">
					<div className="row">
						<div className="col-lg-8 col-md-10 ml-auto mr-auto">
							<div className="section-title">
								<h2 className="title">
									HIS Implementation
								</h2>
							</div>
						</div>
					</div>
					<div className="row">
						{dataSteps.data.home[0].content.steps.his.map(
							(step, index) => (
								<div className="col-lg-3 col-md-12" key={index}>
									<div className="work-process">
										<div className="box-loader">
											{" "}
											<span></span>
											<span></span>
											<span></span>
										</div>
										<div className="step-num-box">
											<div className="step-icon">
												<span>
													<i
														className={`${step["icon"]}`}
													></i>
												</span>
											</div>
											<div className="step-num">
												{step["count"]}
											</div>
										</div>
										<div className="step-desc">
											<h4 className="font-weight-bold text-black">{step["title"]}</h4>
											<br />
											<p className="lead mb-0 font-weight-bold">
												{step["description"]}
											</p>
										</div>
									</div>
								</div>
							)
						)}
					</div>
				</div>
			</section>
		</>
	);
};
export default Steps;
