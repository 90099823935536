import React from "react";
import ScrollTop from "./ScrollTop";

const Footer2 = () => {
    return (
        <>
            {/* footer start */}
            <footer
                className="footer bg-contain bg-pos-lt"
                data-bg-img="images/bg/05.png"
            >
                <div className="primary-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-logo">
                                    <img
                                        id="footer-logo-img"
                                        src="images/Logo.png"
                                        className="img-center"
                                        alt=""
                                    />
                                </div>
                                <p className="mb-0">
                                    Sureze is a software development company based in Malaysia. We have built a 
                                    reputation of delivering customizable cutting-edge technology solutions to
                                    a multitude of diverse business clients nationwide.
								</p>
                            </div>
                            <div className="col-lg-4 col-md-6 pl-md-5 sm-mt-5 footer-list justify-content-between d-flex">
                                <ul className="list-unstyled w-100">
                                    <li>
                                        <p className="font-weight-bold">Directory</p>
                                    </li>
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        <a href="/about">About Us</a>
                                    </li>
                                    <li>
                                        <a href="/contact">Contact Us</a>
                                    </li>
                                </ul>
                                <ul className="list-unstyled w-100">
                                    <li>
                                        <p className="font-weight-bold">Services</p>
                                    </li>
                                    <li>
                                        <a href="/health-info-system">Health Information System</a>
                       
                                    </li>
                                    <li>
                                        <a href="machine-integration">Machine Integration</a>
                                    </li>
                                    <li>
                                        <a href="/database-migration">Database Migration</a>
                                    </li>
                                    <li>
                                        <a href="/software-development">Software Development</a> 
                                    </li>
                                    <li>
                                        <a href="/maintenance">Support & Maintenance</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-12 md-mt-5">
                                <ul className="media-icon list-unstyled">
                                    <li>
                                        Main Branch: 
                                        <br />
                                        <a target="_blank" href="https://www.google.com.my/maps/place/Block+D,+Plaza+Paragon+Point,+Jalan+Medan+Pb+2a,+Seksyen+9,+43650+Bandar+Baru+Bangi,+Selangor/@2.9612284,101.7518877,17z/data=!3m1!4b1!4m5!3m4!1s0x31cdcbd319c84a35:0xad4d15905bd9488f!8m2!3d2.961223!4d101.7540764">
                                            <b>{" "}
                                            D-10-2, Block D, Plaza Paragon Point, Jalan Medan Pusat Bandar 5, 43650 
                                            Bandar Baru Bangi, Selangor Darul Ehsan.
											</b>
                                        </a>
                                    </li>
                                    <li>
                                        Pakistan Branch: 
                                        <br />
                                        <a target="_blank" href="https://www.google.com.my/maps/place/Malikabad+Shopping+Center/@33.6402099,73.0736206,17z/data=!3m1!4b1!4m5!3m4!1s0x14e3d7c028cd49d9:0x26940945443d7b5f!8m2!3d33.6402055!4d73.0758093">
                                            <b>{" "}
                                            S 58, 2nd floor, malikabad Shopping centre, Rawalpindi.
											</b>
                                        </a>
                                    </li>
                                    <li>
                                        Email:{" "}
                                        <a href="mailto:info@sureze.com">
                                            <b>info@sureze.com</b>
                                        </a>
                                    </li>
                                    <li>
                                        Phone:{" "}
                                        <a href="tel:+0358874452">
                                            <b>+03-58874452</b>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="secondary-footer">
                    <div className="container">
                        <div className="copyright">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-12">
                                    {" "}
                                    <span>
                                        Copyright 2020 Sureze.{" "}
										| All Rights Reserved
									</span>
                                </div>
                                <div className="col-lg-6 col-md-12 text-lg-right md-mt-3">
                                    <div className="footer-social">
                                        <ul className="list-inline">
                                            <li className="mr-2">
                                                <a href="/#">
                                                    <i className="fab fa-facebook-f"></i>{" "}
													Facebook
												</a>
                                            </li>
                                            <li className="mr-2">
                                                <a href="/#">
                                                    <i className="fab fa-twitter"></i>{" "}
													Twitter
												</a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                    <i className="fab fa-google-plus-g"></i>{" "}
													Google Plus
												</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* footer end */}
            <ScrollTop />
        </>
    );
};

export default Footer2;
