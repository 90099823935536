import React from "react";
import dataSteps from "../../data.json";

const Steps = () => {
	return (
		<>
			<section className="pos-r o-hidden text-center wow fadeInLeft">
				<div className="bg-animation">
					<img
						className="zoom-fade"
						src="images/pattern/01.png"
						alt=""
					/>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-10 ml-auto mr-auto">
							<div className="section-title">
								<h2 className="title">
									Database Migration Stages
								</h2>
							</div>
						</div>
					</div>
					<div className="row">
						{dataSteps.data.home[0].content.steps.databasemigration.map(
						(step, index) => (
						<div className="col-lg-4 col-md-12" key={index}>
							<div className="work-process">
								<div className="box-loader">
									{" "}
									<span></span>
									<span></span>
									<span></span>
								</div>
								<div className="step-num-box">
									<div className="step-icon">
										<span>
											<i className={`${step["icon"]}`}></i>
										</span>
									</div>
									<div className="step-num">
										{step["count"]}
									</div>
								</div>
								<div className="step-desc font-weight-bold">
									<h4 className="font-weight-bold">{step["title"]}</h4>
									<p className="mb-0">
										{step["description"]}
									</p>
								</div>
							</div>
						</div>
						)
						)}
					</div>
				</div>
			</section>
		</>
	);
};
export default Steps;
