import React from "react";
import About from "./About";
import Clients from "./Team";
import Tabs from "./Tabs";
import HeroSection from "../../homeOne/content/HeroSection";
import Contact from "./Contact";

const PageContent = () => {
	return (
		<>
			<HeroSection />
			<div className="page-content transparent-bg">
				<Tabs />
				<About />
				<Clients />
				<Contact />
			</div>
		</>
	);
};
export default PageContent;
