import React from "react";
import dataAbout from "../../../data.json";
const URL = require("../../../constants");

const About = () => {
	return (
		<>
			<section className="custom-pb-10" data-bg-color="#fbfbfb"
			data-bg-img="images/bg/data-bg.png">
				<div className="container">
					<div className="row align-items-center text-center">
						<div className="col-lg-6 col-md-8 ml-auto mr-auto wow fadeInRight">
							<div className="section-title mb-3">
								<div className="title-effect title-effect-2">
									<div className="ellipse"></div>{" "}
									<i className="la la-cube"></i>
								</div>
								<h2>
									<span className="text-theme font-weight-bold">
										Empowering your Data
									</span>
								</h2>
								<br />
								<p className="text-theme font-weight-bold">
									We understand that many hospital lab results from their applications and machines.
									If we are able to design an interface solution that doesnt require printing, we can 
									reduce paper consumption and human errors.
								</p>
								<br />
								<p className="font-weight-bold text-theme">
									With years of experience integrating with 3rd party systems and equipments,
									Sureze specialize in unifying data so that you can easily access and analyze them.
								</p>
								<br />
								<p className="font-weight-bold text-theme">
									We enable lab management software to directly interface with a variety of lab equipments
									by integrating directly from the machine or through a middleware.
								</p>
							</div>
							<p className="lead">
								{dataAbout.data.services[0].about.description}
							</p>
						</div>
					</div>
				</div>
			</section>
			<section
				className="p-0 pos-r"
				data-bg-img={`${dataAbout.data.services[0].about.img_2}`}
			>
			</section>
		</>
	);
};

export default About;
